import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import Section from 'cl-ui/components/Section';

import MainHeader from 'src/components/content/MainHeader';
import NewsGrid from 'src/components/news/NewsGrid';
import NewsGridHeader from 'src/components/news/NewsGridHeader';
import NewsTeaser from 'src/components/news/NewsTeaser';
import NavigationToggle from 'src/components/navigation/NavigationToggle';
import Projects from 'src/components/project/Projects';
import DataProvider from 'src/providers/DataProvider';
import { transformNews } from 'src/data/transformers';

const Index = ({ data, pageContext, location }) => {
  const {
    headerImage,
    craft: { news, countryData, globalSets },
  } = data;

  const { introText } = globalSets.find(set => set.introText);
  const { t } = useTranslation();

  return (
    <DataProvider entries={{ news, countryData }}>
      <NavigationToggle />
      <MainHeader image={headerImage} />
      <Section bgcolor="secondary.dark" color="common.white" py={8}>
        <Container maxWidth="xl">
          <Projects introText={introText} />
        </Container>
      </Section>
      <Section py={8}>
        <Container maxWidth="xl">
          <Box>
            <NewsGridHeader
              title={t('labels.latestNews')}
              linkText={t('labels.visitNewsroom')}
              linkPage={'news'}
            />
            <NewsGrid
              news={news}
              Component={NewsTeaser}
              transformFn={transformNews}
            />
          </Box>
        </Container>
      </Section>
    </DataProvider>
  );
};

export default Index;

export const query = graphql`
  query HomepageQuery($locale: String) {
    headerImage: file(relativePath: { eq: "header/cotton.jpg" }) {
      ...headerImage
    }
    craft {
      globalSets(site: [$locale]) {
        ... on Craft_map_GlobalSet {
          introText
        }
      }
      countryData: entries(
        section: "countryData"
        site: [$locale]
        orderBy: "countryName"
        status: "enabled"
      ) {
        sectionHandle
        ...countryData
      }
      news: entries(
        site: [$locale]
        type: "news"
        limit: 4
        orderBy: "dateCreated DESC"
      ) {
        dateCreated # @formatDateTime(format: "d.m.Y")
        title
        ...newsData
      }
    }
  }
`;
