import React from 'react';
import { Link } from 'gatsby';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Section from 'cl-ui/components/Section';

import { useLocalization } from 'src/providers/LocalizationProvider';
import Logo from 'src/components/Logo';
import Typography from 'src/components/Typography';
import LanguageSwitcher from 'src/components/navigation/LanguageSwitcher';

const useStyles = makeStyles(theme => ({
  mainHeader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  btnLink: {
    textDecoration: 'none',
  },
  button: {
    [theme.breakpoints.down('md')]: {
      padding: `1rem`,
    },
  },
  logo: {
    marginTop: theme.spacing(2),
    height: 60,
    [theme.breakpoints.up('md')]: {
      height: 120,
    },
  },
  hero: {
    marginBottom: theme.spacing(8),
  },
  langSwitch: {
    // backgroundColor: theme.palette.common.faded,
    display: 'inline-flex',
  },
  overlayGradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: '-1',
    background: 'linear-gradient(180deg, rgba(0,0,0,0.35) 5%, rgba(0,0,0,0))',
  },
  third: {
    flexBasis: '33%',
  },
  fullWidth: {
    width: '100%',
  },
}));

const MainHeader = ({ image }) => {
  const classes = useStyles();
  const { navigation, t, i18n } = useLocalization();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Section
      py={16}
      display="flex"
      justifyContent="center"
      alignItems="center"
      color="common.white"
      fullHeight={true}
      imageSrc={image.childImageSharp.fluid.src}
    >
      <div className={classes.overlayGradient} />
      <Container maxWidth="lg">
        <Typography
          className={classes.hero}
          variant="h2"
          component="h1"
          align="center"
          gutterBottom
          px={4}
        >
          {t('hero.home')}
        </Typography>
      </Container>
      <Container maxWidth="xl">
        <Grid py={4} container spacing={3}>
          {navigation.main.map(page => (
            <Grid key={page.id} item xs={12} sm={6} lg={3}>
              <Link className={classes.btnLink} to={page.path}>
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                >
                  {page.title}
                </Button>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Box
        p={[2]}
        className={classes.mainHeader}
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box className={classes.third}>
          {!isMobile && (
            <LanguageSwitcher classes={{ root: classes.langSwitch }} />
          )}
        </Box>
        <Box display="flex" justifyContent="center" className={classes.third}>
          <Logo className={classes.logo} type="light" />
        </Box>
        <Box className={classes.third} />
      </Box>
    </Section>
  );
};

export default MainHeader;
